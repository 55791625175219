<template>
  <v-container >
    <v-layout align-center justify-center>
      <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        top
        :color="color" dark
      >
        {{text}}
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-flex xs12  sm6 md5 lg4 >
        <v-card class="elevation-12">
    <!--       <v-toolbar dark color="primary">
            <v-toolbar-title align="center">Login</v-toolbar-title>
          </v-toolbar> -->
          <v-card-text class="mt-2">
            <div class="logo my-3" alt="DevCode" >
              <img :src="logo" width="90%" height="80%" class="mb-5" >
            </div>

            <v-form>

                <!-- EMAIL -->
              <v-text-field
                v-model="email"
                prepend-icon="email"
                label="Correo"
                required
                type="email">
              </v-text-field>

                <!-- PASSWORD -->
              <v-text-field
                v-model="password"
                prepend-icon="lock"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="contraseña"
                hint="At least 8 characters"
                autocomplete="off"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>

              <v-flex xs12>
                <v-card v-if="error" color="red lighten-1" class=" px-2 py-2">
                  <div class="white--text">
                    {{ error }}
                  </div>
                </v-card>
              </v-flex>

            </v-form>
          </v-card-text>

              <!-- BOTONES -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  outlined color="primary" :to="{name: 'registro'}">Registrarse</v-btn>
            <v-btn  color="primary" dark @click="ingresar2" class="elevation-5">Login</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1" dark small
              :to="{name: 'olvidacontra'}">Olvide mi contraseña.
            </v-btn>
          </v-card-actions>

          <div style="padding: 10px" align="right">
            <!-- <v-btn  color="orange" small text :to="{name: 'olvidacontra'}">Olvide mi contraseña.</v-btn> -->
          </div>

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      password: '',
      email: '',

      precioutil: '',

      snackbar: false,
      y: 'top',
      x: null,
      mode: '',
      timeout: 8000,
      text: '',

      isPaste: false,
      drawer: null,
      show1: false,
      error: '',
      color: '',

      logo: '',

      rules: {
        required: value => !!value || 'Requerido.',
        min: v => v.length >= 8 || 'Minimo 8 caracteres',
        emailMatch: () => ('El correo y/o la contraseña no son correctos')
      },

      NombreAsig: '',
      Nomuser: ''
    }
  },

  created () {
    this.$http.get('api/v1/logos.list').then(response => {
      this.logo = response.body.Logourl
    }).catch(error => { console.log(error) })
  },

  computed: {
    ...mapGetters('carrito', ['getTC']),
    ...mapGetters('Login', ['getdatosUsuario', 'getLogeado', 'getModo', 'getClientes', 'getCliente'])
  },

  methods: {
    ...mapActions('Login', ['validarUser', 'GetInfoUser']),
    ...mapActions('carrito', ['traerTC']),
    ...mapActions('config', ['setPrecioutil']),

    ingresar2 () {
      this.error = null

      var md5 = require('md5')
      var payload = { Username: this.email, Password: md5(this.password) }
      var usuario = { Email: this.email, Password: md5(this.password) }

      this.validarUser(payload).then(response => {
        // console.log("Login Response",response)

        // Asigno token a variable
        var token = response.token
        // Guardo el Token

        this.$store.dispatch('guardarToken', token)

        // Mando a consultar la informacion del cliente
        this.GetInfoUser(usuario).then(response => {
          // console.log("GetInfoUser.response", response)
          // this.$store.dispatch("guardarNivel", response.nivel )

          if (response === false) {
            this.text = 'No existe el usuario o sus datos son incorrectos'
            this.snackbar = true
            this.color = 'orange'
          } else {
            // this.text     = "Iniciaste sesión"
            // this.snackbar = true
            // this.color = 'success'

            if (this.getModo == 'vendedor') {
              this.traerTema(3)
            }

            // Traer el tipo de cambio
            this.traerTC().then(response => {
              // Validar si es cliente del usuario
              if (this.getCliente != '') {
                this.precioutil = this.getCliente.precioutil
                // Validar si es cleinte del vendedor

                this.setPrecioutil(this.getCliente.precioutil)
              } else if (this.getClientes != '') {
                this.precioutil = this.getClientes.precioutil
              }

              // Ver el tipo de moneda
              if (this.$store.state.tipomoneda == 'D') {
                this.moneda = true
              } else {
                this.moneda = false
              }

              this.$router.push({ name: 'home' }).catch(err => { console.log(err) })
            }).catch(err => { console.log(err) })
          }
        }).catch(err => { console.log(err) })
      }).catch(err => {
        // Si la respuesta es 401 muestro mensaje
        if (err.status === 401) {
          this.text = 'No existe el usuario o sus datos son incorrectos'
          this.snackbar = true
          this.color = 'orange'
        }
      })
    }
  }
}

</script>

<style scoped>

  .logo{
    align-content: "center";
    text-align: center;

  }
</style>
